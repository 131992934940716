


























































































































































































































































































































































































































































































@import '../../../../../assets/styles/components/formularios';
@import '../../../../../assets/styles/components/modal-cadastro';
.v-input__control{
  padding: 15px 0px;
}
.v-text-field.v-text-field--enclosed .v-text-field__details .v-messages {
  position: relative;
  bottom: -0px; 
}
.results-campo {
  .v-input__slot {
    margin-bottom: 0 !important;
    min-height: 30px !important;
  }
}
